<template>
  <div class="login-form">
    <div class="login-logo-container">
      <!-- <img src="../../assets/menu-logo.png" /> -->
      <div class="imgclass"></div>
    </div>
    <el-form :model="form" ref="form" :rules="rules">
      <el-form-item label="账号：" prop="userName">
        <el-input v-model="form.userName" :maxlength="25" />
      </el-form-item>
      <el-form-item label="密码：" prop="userPass">
        <el-input v-model="form.userPass" type="password" :maxlength="50" />
      </el-form-item>
    </el-form>
    <!-- 验证码模块 start -->
    <div class="identify">
      <p class="text">验证码： </p>
      <el-input class="codeinput" v-model="userCode" :maxlength="4" style="width：50%"></el-input>
      <SIdentify :identifyCode="identifyCode" @refresh="refreshCode"></SIdentify>
    </div>
    <!-- 验证码模块 end -->
    <div class="_btn-wrapper" style="text-align:center; margin-top:32px;">
      <el-button type="primary" size="large" class="_btn" @click="submitForm">
        登
        <span style="margin-left:2em;"></span>录
      </el-button>
      <!-- <div style="position:relative; top:16px;">
        <el-button type="text" size="small" @click="nextForm">修改密码？</el-button>
      </div> -->
    </div>

  </div>
</template>

<script>
import SIdentify from './components/identify' // 验证码组件
import loginApi from '@/xhr/login'
export default {
  props: {
    defaultPhone: String,
  },
  components: {
    SIdentify,
  },

  data () {
    return {
      identifyCode: '',
      btn1: 'fhjsdhjfshjfhewfkkjf',
      identifyCodes: 'abcdefghijklnmopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
      userCode: '',
      form: {
        userName: this.defaultPhone,
        userPass: '',

      },

      rules: {
        userName: [{ required: true, message: '请输入账号' }],
        userPass: [{ required: true, message: '请输入密码' }],
      },
    }
  },
  mounted () {
    this.makeCode(this.identifyCodes, 4);
  },

  watch: {
    defaultPhone (phone) {
      this.form.userName = phone
    },
  },

  methods: {
    randomNum (min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    // 刷新验证码
    refreshCode () {
      this.identifyCode = '';
      this.makeCode(this.identifyCodes, 4);
    },
    makeCode (o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[
          this.randomNum(0, this.identifyCodes.length)
        ];
      }
      console.log(this.identifyCode);
    },
    submitForm () {
      // 验证码

      // 登录方法在 vuex 中
      this.$refs.form.validate(valid => {
        if (valid) {
          if (this.userCode === '') {
            this.$message.error('请输入验证码')
            return
          } else if (this.userCode.toLowerCase() !== this.identifyCode.toLowerCase()) {
            this.$message.error('验证码错误')
            // 刷新验证码
            this.refreshCode()
            return
          }
          let obj = {
            username: this.form.userName,
            password: this.form.userPass,
          }
          loginApi.loginIn(obj).then(res => {
            if (res.data.code === '200') {
              sessionStorage.setItem('token', res.data.data.token)
              this.$router.push('/index')
            } else {
              this.$message.error(res.data.message)
            }
          })
        }
      })
    },
  },
}
</script>
<style lang="scss">
.imgclass{
  background-image: url(../../assets/menu-logo.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%
}
</style>
<style lang="scss">
@import "./login.scss";

.login-form {
  @extend .form-container;

  .login-logo-container {
    @include top-center(140px);
  }

  ._btn {
    @extend .form-button;
  }

  @include laptop(1600px);

  @include remove-require-star();
}
</style>
