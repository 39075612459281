<template>
  <div class="login-page">
    <div class="login-page__title"></div>
    <div class="login-page-container">
      <login-form :default-phone="defaultPhone" />
    </div>
  </div>
</template>

<script>
import LoginForm from './LoginForm'
export default {
  components: {
    LoginForm,
  },

  data () {
    // http://syzentao.sayyoo.cn/zentao/task-view-1096.html
    // 有可能需要直接访问忘记密码
    return {
      // n: 0,
      defaultPhone: '',
    }
  },

  mounted () {
    // this.n = this.$route.query.name === 'forgot' ? 1 : 0

  },

  methods: {
    // handleResetSuccess (phone) {
    //   this.n = 0
    //   this.defaultPhone = phone
    //   console.log({ phone })
    // },

    // handleSwitch (n) {
    //   this.n = n === 0 ? 1 : 0
    // },

  }
}
</script>

<style lang="scss">
.login-page {
  background-image: url(../../assets/login-bg.png);
  // background-image: linear-gradient(#39a1e7, #fff);
  width: 100vw;
  min-height: 100vh;
  background-repeat: no-repeat;
  background-size: cover;
}
.login-page__title {
  color: white;
  height: 60px;
  width: 900px;
  background-image: url(../../assets/logo-new.png);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  position: absolute;
  top: 55px;
  left: calc((100% - 900px) / 2);
}
.login-page-container {
  width: 40%;
  max-width: 440px;
  min-width: 310px;
  margin: 0 auto;
  padding: 5% 0;
}

@media screen and (max-width: 1600px) {
  .login-page-container {
    width: 35%;
  }
  .login-page__title {
    // height: 42px;
  }
}
</style>
